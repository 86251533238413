const gogApi = (httpClient: any) => {
  const ALBUMS_GOG_HTML_URL = 'https://albums.wix.com/gog.html';

  const getAllGogs = async (instanceId: string, instance: string) => {
    const result = await httpClient.get(
      `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}?instance=${instance}`,
    );
    return result.data;
  };

  const getGogAlbums = async (
    instanceId: string,
    instance: string,
    gogId: string,
  ) => {
    const result = await httpClient.get(
      `https://www.wix.com/_api/albums-node-server/getGogData?instanceId=${instanceId}&instance=${instance}&gogId=${gogId}`,
    );
    return result.data;
  };

  const addAlbumToGog = async (
    instance: string,
    instanceId: string,
    gogId: string,
    albumId: string,
    orderIndex: number,
  ) => {
    const result = await httpClient.post(
      `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/?instance=${instance}`,
      { albumId, orderIndex },
    );
    return result.data;
  };

  const removeAlbumFromGog = async (
    instance: string,
    instanceId: string,
    gogId: string,
    albumId: string,
  ) => {
    const result = await httpClient.delete(
      `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/${albumId}?instance=${instance}`,
    );
    return result.data;
  };

  const getGogFromHtml = async (instance: string) => {
    const result = await httpClient.get(
      `https://www.wix.com/_api/albums-node-server/mobile/albums`,
      {
        headers: {
          authorization: instance,
        },
      },
    );
    return result.data;
  };

  const getMasterGogData = async () => {
    const result = await httpClient.get(
      `https://www.wix.com/_api/albums-node-server/getMasterGogData`,
    );
    return result.data;
  };

  const checkIfHasBusinessPackage = async (
    metasiteId: string,
    locale: string,
    instance: string,
  ) => {
    try {
      const result = await httpClient.get(
        `https://www.wix.com/_api/albums-node-server/hasBusinessPackage?metasiteId=${metasiteId}&locale=${locale}`,
        {
          headers: {
            Authorization: instance,
          },
        },
      );
      return result.data;
    } catch (e: any) {
      console.log({ e });
    }
  };

  const getPublishedAlbums = async (instance: string, instanceId: string) => {
    try {
      const result = await httpClient.get(
        `https://www.wix.com/_api/albums-node-server/getAlbums?instanceId=${instanceId}&instance=${instance}&filter=published`,
        {
          headers: {
            Authorization: instance,
          },
        },
      );
      return result.data;
    } catch (e: any) {
      console.log({ e });
    }
  };

  const updateAlbum = async (
    instanceId: string,
    gogId: string,
    instance: string,
    albumId: string,
    orderIndex: number,
  ) => {
    try {
      const result = await httpClient.get(
        `https://www.wix.com/_api/albums-node-server/updateAlbumWebAppData?instanceId=${instanceId}&gogId=${gogId}&albumId=${albumId}&instance=${instance}&orderIndex=${orderIndex}`,
      );
      // const result = await httpClient.put(
      //   `https://www.wix.com/_api/albums-webapp/v1/gogs/${instanceId}/${gogId}/albums/${albumId}/order?instance=${instance}`,
      //   { orderIndex },
      // };
      return result.data;
    } catch (error) {
      console.log({ error });
    }
  };

  const initGogOrderIndex = async (
    instanceId: string,
    gogId: string,
    instance: string,
  ) => {
    try {
      const result = await httpClient.get(
        `https://www.wix.com/_api/albums-node-server/initGogOrderIndex?instanceId=${instanceId}&instance=${instance}&gogId=${gogId}`,
      );
      return result.data;
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    ALBUMS_GOG_HTML_URL,
    getAllGogs,
    getGogAlbums,
    addAlbumToGog,
    removeAlbumFromGog,
    getGogFromHtml,
    getMasterGogData,
    checkIfHasBusinessPackage,
    getPublishedAlbums,
    updateAlbum,
    initGogOrderIndex,
  };
};

export default gogApi;
